<template>
  <div>
    <b-card no-body ref="credit_top">
      <b-card-header>
        <h4 class="card-title">
          ทำรายการเติมเครดิต
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">Username</label>
                  <v-select v-model="selected_user" :options="paginated" :filterable="false" @search="onSearch" label="title" combobox-class="form-control" placeholder="เลือก Username">
                    <template #option="{ title, full_name }">
                      {{ title }} ({{ full_name }})
                    </template>
                  </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">จำนวนเงิน</label>
                  <input type="text" v-model="credit.process" class="form-control"  maxlength="10" placeholder="ข้อมูลจำนวนเงิน" style="text-align:right;">
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">ประเภท</label>
                  <b-form-select v-model="credit.type" :options="option_type" />
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6" v-if="credit.type != '3'">
              <b-form-group>
                  <label class="control-label">บันทึกลง Transaction</label>
                  <b-form-select v-model="credit.transaction" :options="option_transactions" :disabled="credit.type == '2'" />
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6" v-if="credit.type == '3'">
              <b-form-group>
                  <label class="control-label">เลือก Promotion</label>
                  <b-form-select v-model="credit.promotion" :options="option_promotions" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-12 col-sm-6">
              <b-form-group>
                  <label class="control-label">หมายเหตุ</label>
                <input type="text" v-model="credit.description" class="form-control" placeholder="ระบุหมายเหตุ">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <button @click="submit_credit()" type="button" class="btn bg-gradient-success waves-effect waves-light text-white"><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" />บันทึก</button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการเครดิต
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-9" class="pr-1 pl-1 mb-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1"> ค้นหา </b-button>
              <b-button @click="this.filter=''" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light"> ออกรานงาน Excel </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1">
              <b-row>
                <div class="btn-group btn-group-sm mx-auto" role="group">
                  <b-button variant="outline-secondary" class="btn" disabled>type: </b-button>
                  <b-button class="btn btn-success" @click="sorted='type:1';fetch()">เพิ่ม</b-button>
                  <b-button class="btn btn-danger" @click="sorted='type:2';fetch()">ลด</b-button>
                  <b-button class="btn btn-info" @click="sorted='type:3';fetch()">โปร</b-button>
                  <b-button class="btn btn-warning" @click="sorted='type:4';fetch()">ฟรี</b-button>
                  <b-button class="btn btn-secondary" @click="sorted='type:5';fetch()">เช็ค</b-button>
                  <b-button class="btn bg-gradient-primary" @click="sorted='';fetch()">ทั้งหมด</b-button>
                </div>
              </b-row>
            </b-col>
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive style="min-height: 300px" :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(full_name)="data">
                  <div v-if="data.item.type != 5" class="text-left w-150">
                    <h5 class="text-primary">{{ data.item.username }}</h5>
                    <span>({{ data.value }})</span>
                  </div>
                  <div v-else class="text-left text-secondary w-150">
                    <h5>{{ data.item.username }}</h5>
                    <span>({{ data.value }})</span>
                  </div>
                </template>
                <template #cell(type)="data">
                  <span v-if="data.value == 1" class="text-success w-100px"><feather-icon icon="ArrowUpIcon" class="font-medium-3 mr-1" />เพิ่ม</span>
                  <span v-if="data.value == 2" class="text-danger w-100px"><feather-icon icon="ArrowDownIcon" class="font-medium-3 mr-1" />ลด</span>
                  <span v-if="data.value == 3" class="text-info w-100px"><feather-icon icon="ArrowUpIcon" class="font-medium-3 mr-1" />โปร</span>
                  <span v-if="data.value == 4" class="text-warning w-100px"><feather-icon icon="ArrowUpIcon" class="font-medium-3 mr-1" />ฟรี</span>
                  <span v-if="data.value == 5" class="text-secondary w-100px"><feather-icon icon="HelpCircleIcon" class="font-medium-3 mr-1" />เช็ค</span>
                </template>
                <template #cell(credit_before)="data">
                  <span v-if="data.item.type == 1" class="w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 2" class="text-danger w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 3" class="w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 4" class="text-warning w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 5" class="text-secondary w-100px">{{ func.toMoney(data.value) }}</span>
                </template>
                <template #cell(process)="data">
                  <span v-if="data.item.type == 1" class="text-success w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 2" class="text-danger w-100px">-{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 3" class="w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 4" class="text-warning w-100px">{{ func.toMoney(data.value) }}</span>
                  <span v-if="data.item.type == 5" class="text-secondary w-100px">{{ func.toMoney(data.value) }}</span>
                </template>
                <template #cell(credit_after)="data">
                  <span v-if="data.item.type != 5" class="text-primary w-100px">{{ func.toMoney(data.value) }} ฿</span>
                  <span v-else class="text-secondary w-100px">{{ func.toMoney(data.value) }} ฿</span>
                </template>
                <template #cell(created_at)="data">
                  <div v-for="value in data.value.split(' ')" :key="value" > {{ value }} </div>
                </template>
                <template #cell(description)="data">
                  <span v-if="data.value != ''" class="text-info w-100px">{{ data.value }}</span>
                  <span v-if="data.value == '' && data.item.credit_before != data.item.credit_after && data.item.type != 5" class="text-success w-100px"><feather-icon icon="CheckIcon" class="font-medium-3 mr-1" title="ทำรายการสำเร็จ" /></span>
                  <span v-if="data.value == '' && data.item.credit_before != data.item.credit_after && data.item.type == 5" class="text-secondary w-100px"><feather-icon icon="HelpCircleIcon" class="font-medium-3 mr-1" title="ทำรายการสำเร็จ" /></span>
                  <span v-if="data.value == '' && data.item.credit_before == data.item.credit_after" class="text-danger w-100px"><feather-icon icon="XIcon" class="font-medium-3 mr-1" title="ทำรายการไม่สำเร็จ" /></span>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item :to="'/credit/credit_form_detail/' + data.item.id"><span><feather-icon icon="SearchIcon" class="text-success mr-1" /></span>ตรวจสอบ</b-dropdown-item>
                      <b-dropdown-item @click="filter=data.item.username;"><span><feather-icon icon="InfoIcon" class="text-info mr-1" /></span>ดูทั้งหมด</b-dropdown-item>
                      <template v-if="data.item.status == 0">
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="deposit_status('0', data.item)"><span><feather-icon icon="RefreshCwIcon" class="text-warning mr-1" /></span>เติมตรง</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="deposit_status('1', data.item)"><span><feather-icon icon="CheckIcon" class="text-info mr-1" /></span>เติมแล้ว</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="deposit_status('2', data.item)"><span><feather-icon icon="SlashIcon" class="text-danger mr-1" /></span>ไม่สำเร็จ</b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import func from '@core/comp-functions/misc/func'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      limit: 20,
      search: '',
      selected_user: { title: 'เลือก Username', id: '' },
      option_users: [],
      option_type: [
        { text: 'เพิ่ม', value: '1' },
        { text: 'ลด', value: '2' },
        { text: 'โปร', value: '3' },
        { text: 'ฟรี', value: '4' },
        { text: 'เช็ค', value: '5' },
      ],
      option_transactions: [
        { text: 'ไม่', value: '0' },
        { text: 'ใช่', value: '1' },
      ],
      option_promotions: [
        { text: 'เลือก Promotion', value: '0' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      sorted: '',
      filter: '',
      filterOn: [],
      fields: [
        { key: 'full_name', label: 'ชื่อ - นามสกุล', class: 'text-center text-nowrap' },
        { key: 'type', label: 'ประเภท', class: 'text-left text-nowrap' },
        { key: 'credit_before', label: 'ยอดก่อนหน้านี้', class: 'text-right text-nowrap' },
        { key: 'process', label: 'จำนวน', class: 'text-right text-nowrap' },
        { key: 'credit_after', label: 'ยอดคงเหลือ', class: 'text-right text-nowrap' },
        { key: 'created_at', label: 'วันเวลา', class: 'text-center text-nowrap' },
        { key: 'description', label: 'รายละเอียด', class: 'text-center text-nowrap' },
        { key: 'admin_username', label: 'ทำรายการโดย', class: 'text-center' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      t_fetch: 0,
      credit: {
        account_id: '',
        process: '',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        type: '1',
        transaction: '0',
        promotion: '0',
        description: '',
      },
      bank_admin: {'01': '1', '02': '2', '03': '3', '04': '5', '05': '6', '06': '4', '07': '7', '08': '9', '09': 'baac'},
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  computed: {
    filtered() {
      return this.option_users.filter((u) => 
        u.title.toLowerCase().includes(this.search.toLowerCase()) ||
        u.full_name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.fetch_username()
    this.fetch_promotion()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onSearch(query) {
      this.search = query
    },
    onFiltered(filteredItems) {
      if (this.filter != '' && this.filter.startsWith('type:')) {
        this.filter = ''
      }
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `credit/credit_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.sorted != '' ? this.sorted : this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch_username() {
      this.$http.get('credit/username').then(res => { res.data.forEach(data=> this.option_users.push({title: data.account_agent_username !== '' ? data.account_agent_username : data.full_name , id: data.id, full_name: data.full_name })) })
    },
    fetch_promotion() {
      this.$http.get('credit/promotion').then(res => { res.data.forEach(data=> this.option_promotions.push({text: data.title , id: data.id, value: data.id })) })
    },
    submit_credit() {
      let { id, title } = this.selected_user
      this.credit.account_id = id
      if (this.credit.account_id.toString().trim().length == "") {
        return this.warn('กรุณาเลือก Username');
      }
      if (this.credit.process.toString().trim().length == 0) {
        return this.warn('กรุณาระบุจำนวนเงิน');
      }
      if (this.credit.process == 0) {
        return this.warn('จำนวนเงินต้องมากกว่า 0');
      }
      if (this.credit.transaction == '1') {
        if (this.credit.date == '') return this.warn('กรุณาเลือกวันที่');
        if (this.credit.time == '') return this.warn('กรุณาเลือกเวลา');
      }
      this.loading()
      this.$http.post('credit/credit_history_create', this.credit).then(res => {
        this.$swal.close()
        if (res.data.result) {
          this.fetch()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ทำรายการสำเร็จ',
              icon: 'CheckIcon',
              variant: 'success',
              text: ((this.credit.type == '2') ? 'ลด' : 'เพิ่ม') + 'เครดิต ยูสเซอร์ : '+title+' จำนวน => '+this.func.toMoney(Number(this.credit.process))+' บาท',
            },
          })
          this.credit.process = '';
        } else {
          this.warn(res.data.message)
        }
      }).catch((error) => {
        this.$swal.close()
        this.warn('ทำรายการไม่สำเร็จ error:'+error)
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style scoped>
.w-150 {
  width: 150px;
}
.w-100px {
  width: 100px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
